import React from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { PATH_PAGE } from 'src/routes/paths';
import LoadingScreen from 'src/global/LoadingScreen';

import { getUser } from '../../utils/sessionManager';
const currentUser = getUser();

AuthProtect.propTypes = {
	children: PropTypes.node,
};

function AuthProtect({ children }) {
	let isAuthenticated = false;
	if (currentUser && currentUser.wallet_address) isAuthenticated = true;
	const { isLoading } = { isLoading: false };

	if (isLoading) {
		return <LoadingScreen />;
	}

	if (!isAuthenticated) {
		return <Redirect to={PATH_PAGE.auth.login} />;
	}

	return <>{children}</>;
}

export default AuthProtect;
