import React, { createContext, useCallback, useReducer } from 'react';
import userReduce from './reducers';
import * as Service from './services';
import * as ACTIONS from './actions';
import { FETCH_LIMIT } from '../../../constants';

const initialState = {
	user_info: {},
	list: [],
	pagination: { limit: FETCH_LIMIT, start: 0, total: 0, currentPage: 1, totalPages: 0 },
	map_options: { lat: '27.700769', lng: '85.300140', zoom: 12 },
};

export const UserContext = createContext(initialState);
export const UserContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReduce, initialState);

	function setMapOptions(options) {
		dispatch({ type: ACTIONS.SET_MAP_OPTIONS, data: options });
	}

	const listUser = useCallback(async query => {
		const res = await Service.listUser(query);
		dispatch({ type: ACTIONS.LIST_ALL_USERS, data: res });
		return res;
	}, []);

	const deleteUser = useCallback(async id => {
		try {
			return Service.deleteUser(id);
		} catch (err) {
			throw new Error(err?.data?.message);
		}
	}, []);

	const registerUser = async (payload, wallet, address, passcode) => {
		return new Promise((resolve, reject) => {
			Service.registerUser(payload, wallet, address, passcode)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const getUserDetails = useCallback(async payload => Service.getUserDetails(payload), []);

	const updateUser = useCallback(async (id, payload) => Service.updateUser(id, payload), []);
	const updateStatus = useCallback(async (id, payload) => {
		try {
			const response = await Service.updateStatus(id, payload);
			return response?.data;
		} catch (error) {
			throw new Error(error?.data?.message);
		}
	}, []);
	const updateApproval = useCallback(async payload => {
		try {
			const response = await Service.updateApproval(payload);
			return response?.data;
		} catch (err) {
			throw new Error(err?.data?.message);
		}
	}, []);

	const uploadImage = async payload => {
		try {
			const { img } = payload;
			if (!img) throw new Error('Failed to get Image');
			const response = await Service.uploadImage(payload);
			console.log(response);
			return response?.data;
		} catch (error) {
			throw new Error(error?.data);
		}
	};

	function loginUsingMetamask(payload) {
		return new Promise((resolve, reject) => {
			Service.loginUsingMetamask(payload)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					reject(err);
				});
		});
	}

	const getRoles = async payload => {
		return new Promise((resolve, reject) => {
			Service.getRoles(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateRole = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateRole(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const getUserDetailsByWallet = async payload => {
		return new Promise((resolve, reject) => {
			Service.getUserDetailsByWallet(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const updateProfile = async payload => {
		return new Promise((resolve, reject) => {
			Service.updateProfile(payload)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	const addUser = payload => {
		return Service.addUser(payload);
	};

	const archiveToggle = useCallback(async params => {
		const res = await Service.archiveToggle(params);
		// dispatch({ type: ACTIONS.LIST_ALL_USERS, data: res });

		return res;
	}, []);

	return (
		<UserContext.Provider
			value={{
				list: state.list,
				pagination: state.pagination,
				map_options: state.map_options,
				addUser,
				listUser,
				dispatch,
				deleteUser,
				getUserDetails,
				updateUser,
				updateStatus,
				uploadImage,
				loginUsingMetamask,
				setMapOptions,
				updateApproval,
				registerUser,
				getRoles,
				updateRole,
				getUserDetailsByWallet,
				updateProfile,
				archiveToggle,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
