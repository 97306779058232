import { ethers } from 'ethers';
import { WALLET_KEY } from 'src/constants';
import hash from 'object-hash';
export const getWallet = async (privateKey = WALLET_KEY) => {
	const wallet = new ethers.Wallet(privateKey);
	return wallet;
};

export const getAuthSignature = async (date = '') => {
	const wallet = await getWallet();

	const toSignedDate = date || Date.now();

	const signature = await wallet.signMessage(toSignedDate.toString());
	return `${toSignedDate}.${signature}`;
};

export const getDataSignature = async data => {
	const wallet = await getWallet();

	const toBeSigned = hash.MD5(data || { name: 'rasil', number: 9813702400 });
	const signature = await wallet.signMessage(toBeSigned);

	return `${toBeSigned}.${signature}`;
};

export function getUser() {
	if (localStorage.getItem('currentUser') && Object.keys(localStorage.getItem('currentUser')).length) {
		return JSON.parse(localStorage.getItem('currentUser'));
	}
	return null;
}

export function saveUser(userData) {
	localStorage.setItem('currentUser', JSON.stringify(userData));
}

export function saveUserRoles(roles) {
	localStorage.setItem('userRoles', JSON.stringify(roles));
}

export function getUserRoles() {
	return JSON.parse(localStorage.getItem('userRoles'));
}

export function saveUserToken(token) {
	localStorage.setItem('token', token);
}

export function saveUserPermissions(perms) {
	localStorage.setItem('userPermissions', perms);
}

export function getUserPermissions() {
	if (localStorage.getItem('userPermissions') && Object.keys(localStorage.getItem('userPermissions')).length) {
		return JSON.parse(localStorage.getItem('userPermissions'));
	}
	return [];
}

export function logoutUser() {
	localStorage.clear();
	window.location = '/auth/login';
}

export function getUserToken() {
	return localStorage.getItem('token');
}
