import React, { useContext } from 'react';
import { WalletContext } from './modules/wallet/core/context';
import routes, { renderRoutes } from 'src/routes';
import LoadingScreen from './global/LoadingScreen';

function Main() {
	const { loading } = useContext(WalletContext);

	return <div>{loading ? <LoadingScreen /> : renderRoutes(routes)}</div>;
}

export default Main;
