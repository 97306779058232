module.exports = {
	DB: {
		NAME: 'agriclear_admin',
		VERSION: 1,
		TABLES: {
			DATA: 'tbl_data',
			ASSETS: 'tbl_assets',
			DOCUMENTS: 'tbl_docs',
		},
	},
};
