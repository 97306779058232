// ----------------------------------------------------------------------

function path(root, sublink) {
	return `${root}${sublink}`;
}

export const ROOTS = {
	admin: '/admin',
	app: '/app',
	auth: '/auth',
	dashboard: '/',
	product: '/products',
	flora: '/flora',
	fauna: '/fauna',
	trader: '/traders',
};

export const PATH_HOME = {
	dashboard: ROOTS.app,
};

export const PATH_PAGE = {
	auth: {
		root: ROOTS.auth,
		login: path(ROOTS.auth, '/login'),
		loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
		resetPassword: path(ROOTS.auth, '/reset-password'),
		verify: path(ROOTS.auth, '/verify'),
	},
	comingSoon: '/coming-soon',
};

export const PATH_APP = {
	root: ROOTS.dashboard,
	product: {
		list: path(ROOTS.product, ''),
		flora: path(ROOTS.product, '/flora'),
		fauna: path(ROOTS.product, '/fauna'),
	},
	flora: {
		details: path(ROOTS.flora, '/:id'),
	},
	fauna: {
		details: path(ROOTS.fauna, '/:id'),
	},
	trader: {
		list: path(ROOTS.trader, ''),
		details: path(ROOTS.trader, '/:id'),
	},
	admin: {
		users: {
			list: path(ROOTS.admin, '/users'),
			details: path(ROOTS.admin, `/users/:id`),
		},
	},
};
