module.exports = {
	APP_NAME: 'Agriclear Admin',
	APP_VERSION: '1.0',
	WALLET_KEY: '0xa981e2c14fe041b9c0ec1c198fd0f05daf0922b9aa49a2a1c5eaf3eec7099dca',
	CONSUMER_VIEW_URL: process.env.REACT_APP_CONSUMER_VIEW_URL,
	IPFS_URL: process.env.REACT_APP_IPFS_SERVER,
	FETCH_LIMIT: 10,
	APP_CONSTANTS: {
		VERSION: 1,
		PASSCODE_LENGTH: 6,
		LANGUAGE: {
			NP: 'np',
			EN: 'en',
		},
	},
	MAP: {
		DEFAULT_LAT: '27.70076',
		DEFAULT_LNG: '85.30014',
		ZOOM: 10,
	},
	ARCHIVE_REASONS: [
		{ label: 'Sold', value: 'sold' },
		{ label: 'Removed', value: 'removed' },
		{ label: 'Damaged', value: 'damaged' },
		{ label: 'Others', value: 'others' },
	],
	TAGS: {
		FERTILIZER: 'Fertilizer',
		PESTICIDE: 'Pesticide',
		JOURNEY: 'Journey',
	},
	AREA_METRICS: {
		HECTARE: 'Hectare',
		KATTHA: 'Kattha',
		ROPANI: 'Ropani',
		SQ_KM: 'Sq.Km',
	},
	FARM_TYPES: {
		SELF_OWNED: 'Self owned',
		ENTERPRISE_OWNED: 'Enterprise Owned',
	},
	ROLES: {
		ADMIN: 'Admin',
		FARM_ADMIN: 'Farm Admin',
	},
	MEMBERS: {
		TRADER: 'Trader',
	},
	PRODUCT_TYPES: {
		FLORA: 'flora',
		FAUNA: 'fauna',
	},
	KNOWLEDGE_BASES: {
		PST_MGMT: 'pestMgmt',
		DISEASE_MGMT: 'diseaseMgmt',
		HARVEST_MGMT: 'harvestMgmt',
		NURSERY_MGMT: 'nurseryMgmt',
		CROP_MGMT: 'cropMgmt',
		PARASITE_MGMT: 'parasiteMgmt',
		VACCINE: 'vaccine',
		SPECIAL_HUSBANDRY_MGMT: 'specialHusbandaryMgmt',
	},
	LANGUAGES: {
		EN: 'en',
		NP: 'np',
	},
	PRODUCT_CATEGORIES: {
		FLORA: [
			'cereal',
			'legume',
			'vegetable',
			'fruit',
			'oilSeedCrop',
			'beverage',
			'spice',
			'forage',
			'fibre',
			'ornamental',
			'other',
		],
		FAUNA: [
			'medicinalAndAromatic',
			'largeRuminant',
			'smallRuminant',
			'pig',
			'equine',
			'camel',
			'poultry',
			'insect',
			'fish',
			'other',
		],
	},
	REGIONS: {
		HIGH_HILLS: 'High Hills',
		MID_HILLS: 'Mid Hills',
		TERAI: 'Terai',
	},
	FLORA_CLAIMS: [
		{ label: 'Safe', value: 'Safe' },
		{ label: 'Organic', value: 'Organic' },
		{ label: 'Sustainable Production', value: 'Sustainable Production' },
		{ label: 'Naturally Grown', value: 'Naturally Grown' },
		{ label: 'Supports Charity', value: 'Supports Charity' },
		{ label: 'Recyclable', value: 'Recyclable' },
		{ label: 'Locally Sourced', value: 'Locally Sourced' },
		{ label: 'Female Owned Business', value: 'Female Owned Business' },
		{ label: 'Local Employer', value: 'Local Employer' },
		{ label: 'Pesticide Residue Free', value: 'Pesticide Residue Free' },
		{ label: 'Non-GMO', value: 'Non-GMO' },
		{ label: 'Gluten Free', value: 'Gluten Free' },
		{ label: 'Fair Trade', value: 'Fair Trade' },
	],
	FAUNA_CLAIMS: [
		{ label: 'Safe', value: 'Safe' },
		{ label: 'Organic', value: 'Organic' },
		{ label: 'Sustainable Production', value: 'Sustainable Production' },
		{ label: 'Supports Charity', value: 'Supports Charity' },
		{ label: 'Recyclable', value: 'Recyclable' },
		{ label: 'Locally Sourced', value: 'Locally Sourced' },
		{ label: 'Female Owned Business', value: 'Female Owned Business' },
		{ label: 'Local Employer', value: 'Local Employer' },
		{ label: 'Halal', value: 'Halal' },
		{ label: 'Cage Free', value: 'Cage Free' },
		{ label: 'Antibiotics Free', value: 'Antibiotics Free' },
	],
};
