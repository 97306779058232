import * as ACTION from './actions';

export default function reducer(state, action) {
	switch (action.type) {
		case ACTION.INIT_APP:
			return {
				...state,
				address: action.data.address,
				network: action.data.network,
				hasWallet: action.data.hasWallet,
			};
		case ACTION.SET_PASSCODE_MODAL:
			return { ...state, openPasscodeModal: action.data };

		case ACTION.SET_LOADING:
			return {
				...state,
				loading: action.data,
			};

		case ACTION.SET_WALLET:
			return {
				...state,
				wallet: action.data,
			};

		case ACTION.SET_HASWALLET:
			return {
				...state,
				hasWallet: action.data,
			};
		case ACTION.SET_APP_PASSCODE:
			return {
				...state,
				walletPasscode: action.data,
			};
		case ACTION.CHANGE_ISVERIFIED:
			return {
				...state,
				isVerified: action.data,
			};

		default:
			return state;
	}
}
