const server_url = process.env.REACT_APP_API_SERVER;

const base_url = server_url + '/api/v1';
module.exports = {
	AUTH: base_url + '/auth',
	MEMBERS: base_url + '/members',
	USERS: base_url + '/users',
	PRODUCT: base_url + '/products',
	ROLES: base_url + '/roles',
	FLORA: base_url + '/flora',
	FAUNA: base_url + '/fauna',
	BATCH: base_url + '/batches',
};
