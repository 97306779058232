import jwtDecode from 'jwt-decode';

export const validateAccessToken = token => {
	let isValid = false;
	const decoded = jwtDecode(token);

	const { exp } = decoded;

	if (Date.now() <= exp * 1000) isValid = true;
	return isValid;
};

export const getCurrentLocation = () => {
	return new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				resolve(position.coords);
			});
		} else {
			reject(null);
		}
	});
};

export const fileToBase64 = async file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = e => reject(e);
	});

export const validateImage = async file => {
	const { size, type } = file;
	if (size > 153600) {
		throw Error('Image should be of max 150 KB');
	}
	const fileType = type.split('/')[1];
	const validFileType = ['jpeg', 'jpg', 'png'].includes(fileType);
	if (!validFileType) {
		throw Error('Only jpeg, jpg, or png images supported');
	}
};

export const getAuthConfig = (auth_signature, data_signature = null) => {
	const config = {
		headers: {
			auth_signature,
			data_signature,
		},
	};
	return config;
};

export const santizedErrorMsg = err => {
	console.log('MESSAGE=>', err.message);
	if (err.message) return err.message;
	return 'Something went wrong, Please try again!';
};

export const getFormattedGender = gender => {
	if (gender === 'M') return 'Male';
	if (gender === 'F') return 'Female';
	if (gender === 'O') return 'Other';
	return 'Unknown';
};
