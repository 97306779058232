import React from 'react';
import PropTypes from 'prop-types';
import { PATH_APP } from 'src/routes/paths';
import { Redirect } from 'react-router-dom';
import LoadingScreen from 'src/global/LoadingScreen';

import { getUser } from '../../utils/sessionManager';

const currentUser = getUser();

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
	children: PropTypes.node,
};

function GuestProtect({ children }) {
	let isAuthenticated = false;
	if (currentUser && currentUser.wallet_address) isAuthenticated = true;

	const { isLoading } = {
		isLoading: false,
	};

	if (isLoading) {
		return <LoadingScreen />;
	}

	if (isAuthenticated) {
		return <Redirect to={PATH_APP.root} />;
	}

	return <>{children}</>;
}

export default GuestProtect;
