import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboardLayout';
import AuthProtect from 'src/global/Auth/AuthProtect';
import ComponentWrapper from 'src/global/ComponentWrapper';
const UserList = lazy(() => import('src/modules/user/list'));
const UserDetails = lazy(() => import('src/modules/user/details'));

const FloraList = lazy(() => import('src/modules/Product/list/flora'));
const FloraDetails = lazy(() => import('src/modules/Product/details/flora'));
const FaunaList = lazy(() => import('src/modules/Product/list/fauna'));
const FaunaDetails = lazy(() => import('src/modules/Product/details/fauna'));
const TraderList = lazy(() => import('src/modules/member/traders'));
const TraderDetails = lazy(() => import('src/modules/member/traders/details'));
const Profile = lazy(() => import('src/modules/user/profile'));

// ----------------------------------------------------------------------

const AppRoutes = {
	path: PATH_APP.root,
	layout: DashboardLayout,
	guard: AuthProtect,
	routes: [
		{
			exact: true,
			path: PATH_APP.root,
			component: () => <Redirect to={PATH_APP.product.list} />,
		},
		{
			exact: true,
			path: PATH_APP.product.list,
			component: () => <Redirect to={PATH_APP.product.flora} />,
		},
		{
			exact: true,
			path: PATH_APP.product.flora,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.flora,
				},
				{
					name: 'List',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<FloraList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.flora.details,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.flora,
				},
				{
					name: 'Details',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<FloraDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.product.fauna,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.fauna,
				},
				{
					name: 'List',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<FaunaList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.fauna.details,
			breadcrumbs: [
				{
					name: 'Products',
					href: PATH_APP.product.fauna,
				},
				{
					name: 'Details',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<FaunaDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.admin.users.list,
			breadcrumbs: [
				{
					name: 'Users',
					href: PATH_APP.admin.users.list,
				},
				{
					name: 'List',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<UserList />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.admin.users.details,
			breadcrumbs: [
				{
					name: 'Users',
					href: PATH_APP.admin.users.list,
				},
				{
					name: 'Details',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<UserDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.trader.list,
			breadcrumbs: [
				{
					name: 'Traders',
					href: PATH_APP.trader.list,
				},
				{
					name: 'List',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<TraderList {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: PATH_APP.trader.details,
			breadcrumbs: [
				{
					name: 'Traders',
					href: PATH_APP.trader.list,
				},
				{
					name: 'Details',
				},
			],
			component: props => (
				<ComponentWrapper {...props}>
					<TraderDetails {...props} />
				</ComponentWrapper>
			),
		},
		{
			exact: true,
			path: '/profile',
			component: props => <Profile {...props} />,
		},

		// ----------------------------------------------------------------------
		{
			component: () => <Redirect to="/404" />,
		},
	],
};

export default AppRoutes;
