import React, { createContext, useReducer } from 'react';
import languageReduce from './reducers';
import ACTION from './actions';

const initialState = {
  profileLang: localStorage.getItem('lang') || 'en'
};

export const LanguageContext = createContext(initialState);
export const LanguageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(languageReduce, initialState);

  const onChangeLang = lang => {
    localStorage.setItem('lang', lang);
    dispatch({ type: ACTION.CHANGE_LANG, lang });
  };

  return (
    <LanguageContext.Provider value={{ profileLang: state.profileLang, onChangeLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
